export const contractBookLink = 'https://contractbook.com';
export const registrationLink = 'https://connectd.com/join-now';
export const termsAndConditionsLinkFounder = 'https://www.connectd.com/terms-conditions-founders';
export const termsAndConditionsLinkInvestor = 'https://www.connectd.com/terms-conditions-investors';
export const termsAndConditionsLinkNed = 'https://www.connectd.com/terms-and-conditions-advisors';
export const privacyPolicyLink = 'https://www.connectd.com/privacy-policy';
export const montserratFontLink = 'https://fonts.googleapis.com/css?family=Montserrat:400';
export const resourcesLink = 'https://www.connectd.com/resources';
export const partnershipsLink = 'https://www.connectd.com/partnerships';
export const newPulseLink = 'https://www.connectd.com/resource/maximise-the-power-of-new-pulse-smart-match';
export const metricsIntegrationsLink = 'https://www.connectd.com/resource/metrics-vs-integrations';
export const findingTalentLink = 'https://www.connectd.com/resource/finding-talent';
export const accreditedInvestorLink = 'https://www.connectd.com/us/accredited-investor';
export const trustedSenderResourceLink = 'https://www.connectd.com/resource/adding-connectd-as-a-trusted-sender';
export const publishProfileGuideLink =
  'https://www.connectd.com/resource/how-to-populate-and-publish-your-connectd-founder-profile';
export const swappsiLink = 'https://www.swappsi.com/connectd/?utm_source=connectd&utm_medium=website';
export const higgsLink = 'https://www.higgsllp.co.uk/our-people/jamie-partington';
export const exlabsLink = 'https://exlabs.com/cto-for-a-day/';
export const archaxLink = 'https://eu1.hubs.ly/H0bR_zK0';
export const advisorBuildYourProfileTutorialLink =
  'https://www.connectd.com/resource/how-to-build-your-connectd-portfolio-professional-profile';
export const bookMeetingLink =
  'https://meetings-eu1.hubspot.com/emily-crabtree?uuid=6117f4f2-f0df-489c-a884-0c5b5152a3d3';
export const t2pAdvisorTutorialVideoLink =
  'https://player.vimeo.com/video/869462742?h=5f6239ffc7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
export const t2pAdvisorTutorialVideoLinkUS =
  'https://player.vimeo.com/video/889917007?h=0e47b0bcd5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
export const bookPlacementsCallLink = process.env.REACT_APP_BOOK_PLACEMENTS_CALL_URL;
export const placementLearnLink = 'https://www.connectd.com/resource/a-founders-guide-to-connectd-placements';
export const communityLink = process.env.REACT_APP_INVESTOR_COMMUNITY_URL || '';
export const communityAdvisorLink = process.env.REACT_APP_ADVISOR_COMMUNITY_URL || '';
export const bookConsultationLink = process.env.REACT_APP_BOOK_CONSULTATION_URL || '';
export const advisorMentorsLink = 'https://www.connectd.com/mentors';
